/* !
 * jQuery index v1.0.0
 * Copyright 2019, Connecty Inc.
 */
(function($, ADSEC){
	const a = 'is-active';
	const v = 'is-visible';
	const arrowPrev = '<button type="button" class="slick-prev"><svg class="icon"><use xlink:href="/common/svg/sprite.svg#arrow_left" /></svg></button>';
	const arrowNext = '<button type="button" class="slick-next"><svg class="icon"><use xlink:href="/common/svg/sprite.svg#arrow_right" /></svg></button>';

	// メインヴィジュアル
	const mainSlider = () => {
		$('.idx-main-slider').slick({
			infinite: true,
			speed: 1500,
			autoplay: true,
			autoplaySpeed: 5000,
			slidesToShow: 1,
			arrows: false,
			dots: true,
			fade: true,
			centerMode: true,
			centerPadding: '100px',
			appendDots: $('.idx-main-inner'),
		});
	};

	const newsTicker = () => {
		// ニュースティッカー
		// 〇文字目移行隠
		// const cutTickerText = (_cnt) => {
		// 	$('.idx-main-ticker-ul').find('.mod-newsList01 .__title').each((idx, ele) => {
		// 		var rawText = $(ele).text();
		// 		if(rawText.length > _cnt) {
		// 			$(ele).text(rawText.substring(0, _cnt) + '...');
		// 		}
		// 	});
		// };
		// if(ADSEC.va.window.width >= 1024) {
		// 	cutTickerText(50);
		// } else if(ADSEC.va.window.width < 1024 && ADSEC.va.window.width >= 768) {
		// 	cutTickerText(50);
		// } else if(ADSEC.va.window.width < 768) {
		// 	cutTickerText(45);
		// }
		$(window).on('resize', () => {
			let winW = $(window).width();
			// タイトルの高さを揃える
			if(winW >= 1024) {
				$('.idx-main-ticker-ul li .__title').css('height', 'auto');
				let heightArr = [];
				$('.idx-main-ticker-ul li').each((idx, ele) => {
					let thiH = $(ele).find('.__title').outerHeight();
					heightArr.push(thiH);
				});
				let maxHeight = Math.max(...heightArr);
				$('.idx-main-ticker-ul .__title').css('height', maxHeight +'px');
			} else {
				$('.idx-main-ticker-ul li .__title').css('height', 'auto');
			}
		}).resize();

		// ティッカー スライドセット
		$('.idx-main-ticker-ul').slick({
			infinite: true,
			speed: 600,
			autoplay: true,
			autoplaySpeed: 5000,
			slidesToShow: 1,
			vertical: true,
			prevArrow: arrowPrev,
			nextArrow: arrowNext,
			responsive: [{
				breakpoint: 1025,
				settings: {
					vertical: false
				}
			}]
		});
	};

	// <TABLET/SP>メインビジュアル高さ調整
	const mainVisHeight = () => {
		$(window).on('resize', () => {
			let winW = $(window).width();
			let winH = $(window).height();
			if(winW <= 1024) {
				let tickerH = $('.idx-main-ticker').outerHeight();
				$('.idx-main-inner').css('height', winH - tickerH);
			} else {
				$('.idx-main-inner').css('height', winH - 50);
			}
		}).resize();
	};
	// TOPメインビジュアル高さ調整
	const mainVis = () => {
		function setHeight(){
			let vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty('--vh', `${vh}px`);
		}

		// 2.初期化
		setHeight();

		// 3.ブラウザのサイズが変更された時・画面の向きを変えた時に再計算する
		window.addEventListener('resize', setHeight);
	};

	const productsSlider = () => {
		// PRODUCS tabletとSPのみスライド
		function sliderSetting(){
			var width = $(window).width();
			if(width <= 768) {
				$('.idx-products-slider').not('.slick-initialized').slick({
					infinite: true,
					speed: 1000,
					autoplaySpeed: 4000,
					autoplay: true,
					slidesToShow: 1,
					arrows: true,
					prevArrow: arrowPrev,
					nextArrow: arrowNext,
					dots: true,
				});
			} else {
				$('.idx-products-slider.slick-initialized').slick('unslick');
			}
		}
		sliderSetting();

		$(window).resize(function(){
			sliderSetting();
		});
	};

	// アニメーション
	const inviewItemsAnimation = () => {
		const anime = 'is-animation';
		$('.js-animation-head01').each((idx, ele) => {
			let text = $(ele).find('.mod-head02-tit').text();
			let textbox = '';
			text.split('').forEach(function(t, i){
				if(t !== ' ') {
					textbox += '<span style="transition-delay:' + 0.05*i + 's;">' + t + '</span>';
				} else {
					textbox += t;
				}
			});
			$(ele).find('.mod-head02-tit').html(textbox);
		});
		$('.js-animation-head02, .js-animation-head03').each((idx, ele) => {
			let text = $(ele).find('.mod-head04-en').text();
			let textbox = '';
			text.split('').forEach(function(t, i){
				if(t !== ' ') {
					textbox += '<span style="transition-delay:' + 0.05*i + 's;">' + t + '</span>';
				} else {
					textbox += t;
				}
			});
			$(ele).find('.mod-head04-en').html(textbox);
		});

		$('.js-animation-head01').each((idx, ele) => {
			$(ele).on('inview', (event, isInView, visiblePartX, visiblePartY) => {
				$(ele).addClass(anime);
			});
		});
		$('.js-animation-head02').each((idx, ele) => {
			$(ele).on('inview', (event, isInView, visiblePartX, visiblePartY) => {
				$(ele).addClass(anime);
			});
		});
		$('.js-animation-head03').each((idx, ele) => {
			$(ele).on('inview', (event, isInView, visiblePartX, visiblePartY) => {
				$(ele).addClass(anime);
			});
		});
		$('.js-animation-block01').each((idx, ele) => {
			$(ele).on('inview', (event, isInView, visiblePartX, visiblePartY) => {
				$(ele).addClass(anime);
			});
		});
	};


	// ==============================================================================
	inviewItemsAnimation();
	productsSlider();
	mainVis();

	if($('.idx-main-slider').length) {
		mainSlider();
	}
	if($('.idx-main-ticker-ul').length) {
		newsTicker();
		mainVisHeight();
	}

})(window.jQuery, window.ADSEC);
